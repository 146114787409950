import axios from 'axios'
import api from '@/utils/endpoints'
import dotenv from 'dotenv'

dotenv.config()

const headers = {
  Accept: 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

export const getlists = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.invoice.get}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const sendInvoice = params => new Promise((resolve, reject) => {
  let { payload } = params
  if (payload.length === undefined) payload = [payload]
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.invoice.sendInvoice}`, { payload }, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const connectQBO = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.invoice.connectQBO}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const callbackQBO = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.invoice.callbackQBO}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})
