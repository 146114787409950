<template>
  <div>Profile</div>
</template>

<script>
import * as invoiceService from '@/services/invoice'

export default {
  created() {
    if (this.$store.state.auth.role === 'Admin' || this.$store.state.auth.role === 'Accountant') {
      if (!localStorage.getItem('qboConnect')) {
        this.connectQBO()
      }
    }
  },
  methods: {
    async connectQBO() {
      this.$swal({
        title: 'Please connect the Quickbooks.',
        text: 'You can select one company',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          const res = await invoiceService.connectQBO()
          window.open(res.data.authorizationCodeUrl, '_self')
          localStorage.setItem('qboConnect', true)
        }
      })
    },
  },
}
</script>

<style>

</style>
